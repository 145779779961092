import { Fragment, FC } from 'react'
import { Descriptions } from 'antd'
import { Text } from '@signifyd/components'
import { EkataObject } from '@signifyd/http'
import styles from '../CaseDetails.less'

import {
  getBooleanTranslation,
  getPhoneIntelligenceLabel,
} from './phoneTranslations'

const { Item } = Descriptions

interface Props {
  ekata: EkataObject
}

export const PhoneIntelligence: FC<Props> = ({ ekata }) => {
  /*
Item components used for headers have empty objects
as children because antd requires it
*/

  return (
    <div data-test-id="phoneIntelligence">
      <Descriptions colon={false} size="small">
        {ekata.alternatePhones.map((phoneDetails) => (
          <Item
            key={phoneDetails}
            label={getPhoneIntelligenceLabel('alternatePhone')}
          >
            <Text>{phoneDetails}</Text>
          </Item>
        ))}
        {ekata.carrier && (
          <Item label={getPhoneIntelligenceLabel('carrier')}>
            <Text>{ekata.carrier}</Text>
          </Item>
        )}
        {ekata.lineType && (
          <Item label={getPhoneIntelligenceLabel('phoneType')}>
            <Text>{ekata.lineType}</Text>
          </Item>
        )}
        {ekata.prepaid && (
          <Item label={getPhoneIntelligenceLabel('prepaid')}>
            <Text>{getBooleanTranslation(ekata.prepaid)}</Text>
          </Item>
        )}
        {ekata.commercial && (
          <Item label={getPhoneIntelligenceLabel('commercial')}>
            <Text>{getBooleanTranslation(ekata.commercial)}</Text>
          </Item>
        )}

        {ekata.belongsTo.map((entity) => (
          <Fragment key={entity.id}>
            {/* See comment on line 20 */}
            <Item
              label={
                <Text weight="semibold" size="lg">
                  {entity.type}
                </Text>
              }
            >
              {}
            </Item>
            <Item label={getPhoneIntelligenceLabel('name')}>
              <Text>{entity.name}</Text>
            </Item>
            {entity.age_range && (
              <Item label={getPhoneIntelligenceLabel('age_range')}>
                <Text>{entity.age_range}</Text>
              </Item>
            )}
            {entity.gender && (
              <Item label={getPhoneIntelligenceLabel('gender')}>
                <Text>{entity.gender}</Text>
              </Item>
            )}
            {entity.link_to_phone_start_date && (
              <Item
                label={getPhoneIntelligenceLabel('link_to_phone_start_date')}
              >
                <Text>{entity.link_to_phone_start_date}</Text>
              </Item>
            )}
          </Fragment>
        ))}
        {/* See comment on line 20 */}
        {ekata.associatedPeople.length > 0 && (
          <Item
            label={
              <Text weight="semibold" size="lg">
                {getPhoneIntelligenceLabel('associatedPeople')}
              </Text>
            }
          >
            {}
          </Item>
        )}
        {ekata.associatedPeople.map((person) => (
          <Item key={person.id} label={person.name}>
            <Text>
              {getPhoneIntelligenceLabel('relation')}:{' '}
              {person.relation || getPhoneIntelligenceLabel('unknown')}
            </Text>
          </Item>
        ))}
        {/* See comment on line 20 */}
        {ekata.currentAddresses.length > 0 && (
          <Item
            label={
              <Text weight="semibold" size="lg">
                {getPhoneIntelligenceLabel('addresses')}
              </Text>
            }
          >
            {}
          </Item>
        )}
        {ekata.currentAddresses.map((address) => {
          const [addressLine1, addressLine2, ...addressDetails] = [
            address.street_line_1,
            address.city,
            address.state_code,
            address.country_code,
            address.postal_code,
          ].filter((detail) => detail)

          return (
            <Fragment key={address.street_line_1}>
              <Item label={getPhoneIntelligenceLabel('address')}>
                <Text className={styles.fullText}>
                  {`${addressLine1}, `}
                  <br />
                  {`${addressLine2}, ${addressDetails.join(' ')}`}
                </Text>
              </Item>
            </Fragment>
          )
        })}
      </Descriptions>
    </div>
  )
}
