export default {
  hide: 'Hide filters',
  show: 'Show filters',
  clearFilters: 'Clear all',
  dateRange: {
    label: 'Date',
    today: 'Today',
    last7Days: 'Last 7 days',
    last30Days: 'Last 30 days',
    fromXToToday: 'From {{- minDate}} to today',
    upToX: 'Up to {{- maxDate}}',
    range: '{{- minDate}} to {{- maxDate}}',
    tooltip:
      "If the order date wasn't present during the order creation request, this is the date the order is received by Signifyd.",
    clearDatesTooltip:
      'To reset the search to the previous 14 days, clear the dates.',
  },
  guaranteeStatus: {
    label: 'Guarantee status',
    APPROVED: 'Approved',
    PENDING: 'Pending',
    DECLINED: 'Declined',
    CANCELED: 'Canceled',
    CANCELLED: 'Canceled',
    IN_REVIEW: 'In review',
    ESCALATED: 'In review',
    PROCESSING_REGUARANTEE: 'Pending',
    UNDEFINED_DISPOSITION: 'Pending',
    NULL: 'Unrequested',
    PROCESSING: 'Processing',
  },
  recommendedAction: {
    label: 'Recommendation status',
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    HOLD: 'Hold',
  },
  claimStatus: {
    label: 'Claim status',
    APPROVED: 'Approved',
    PENDING: 'Pending',
    PENDING_DOCS: 'Pending',
    DECLINED: 'Declined',
    CANCELED: 'Canceled',
    CANCELLED: 'Canceled',
    CLOSED: 'Closed',
    ON_HOLD: 'On hold',
    IN_REVIEW: 'In review',
    UNREQUESTED: 'Unrequested',
    NEED_MORE_INFO: 'Needs more information',
  },
  orderAmount: {
    label: 'Order amount',
  },
  score: {
    label: 'Score',
    min: 'Min ({{minLimit}})',
    max: 'Max ({{maxLimit}})',
    upTo: 'Up to {{max}}',
    atLeast: 'At least {{min}}',
    range: '{{min}} to {{max}}',
  },
  shippingMethod: {
    label: 'Shipping method',
    OTHER: 'Other',
    DEFAULT: 'Default',
    EXPRESS: 'Express',
    ELECTRONIC: 'Electronic',
    FIRST_CLASS: 'First class',
    FIRST_CLASS_INTERNATIONAL: 'First class international',
    FREE: 'Free',
    FREIGHT: 'Freight',
    GROUND: 'Ground',
    INTERNATIONAL: 'International',
    OVERNIGHT: 'Overnight',
    PRIORITY: 'Priority',
    PRIORITY_INTERNATIONAL: 'Priority international',
    PICKUP: 'Pickup',
    SHOPRUNNER: 'ShopRunner',
    STANDARD: 'Standard',
    STORE_TO_STORE: 'Store to store',
    TWO_DAY: '2 day',
    SAME_DAY: 'Same day',
    DELIVERY: 'Delivery',
    COUNTER_PICKUP: 'Counter pickup',
    CURBSIDE_PICKUP: 'Curbside pickup',
    LOCKER_PICKUP: 'Locker pickup',
  },
  advanced: {
    guaranteeRecommendedAction: {
      label: 'Checkout',
      ACCEPT: 'Accept',
      REJECT: 'Reject',
      HOLD: 'Hold',
    },
    label: 'Advanced filters',
    caseStatus: {
      label: 'Case status',
      OPEN: 'Open',
      CLOSED: 'Closed',
    },
    caseType: {
      label: 'Case type',
      LIVE: 'Live',
      TRIAL: 'Trial',
    },
    investigationReviewDisposition: {
      label: 'Order review flag',
      UNSET: 'None',
      GOOD: 'Good',
      FRAUDULENT: 'Bad',
    },
    authorizationGatewayStatus: {
      label: 'Authorization status',
      SUCCESS: 'Success',
      FAILURE: 'Failure',
      ERROR: 'Error',
      PENDING: 'Pending',
      NULL: 'None',
    },
    isTestInvestigation: {
      label: 'Test cases',
      true: 'Test cases only',
      false: 'Exclude test cases',
    },
    returnDecisionCheckpointAction: {
      label: 'Return',
      ACCEPT: 'Accept',
      CREDIT: 'Credit',
      HOLD: 'Hold',
      REJECT: 'Reject',
    },
  },
  placeholder: {
    selectOneOrMore: 'Select one or more',
    selectStatus: 'Select a status',
    min: 'Min',
    max: 'Max',
    to: 'to',
  },
  common: {
    upTo: 'Up to {{currency}} {{max}}',
    atLeast: 'At least {{currency}} {{min}}',
    range: '{{currency}} {{min}} to {{max}}',
  },
  delete: 'Delete',
  selected: '(selected)',
}
