import { FC } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router'
import CaseReviewPage from './pages/CaseReviewPage'
import SearchResultsPage from './pages/SearchResultsPage'
import NewCasePage from './pages/NewCasePage'

const AppRoutes: FC = () => {
  const { search } = useLocation()

  return (
    <Routes>
      <Route path="/orders/search" element={<SearchResultsPage />} />
      <Route path="/orders/search/:searchId" element={<SearchResultsPage />} />
      <Route path="/orders/:caseId" element={<CaseReviewPage />} />
      <Route path="/orders/new-case" element={<NewCasePage />} />
      <Route path="*" element={<Navigate to={`/orders/search${search}`} />} />
    </Routes>
  )
}
export default AppRoutes
