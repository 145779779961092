import { FC } from 'react'
import { InvestigationInfo, INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { SigTag, GuaranteeIcon } from '@signifyd/components'
import { colorJade, colorRed, colorGlitter, colorSlate } from '@signifyd/colors'
import styles from './GuaranteeTag.less'

const colors = {
  [INV_GUARANTEE_DISPOSITION.APPROVED]: ['jade', colorJade],
  [INV_GUARANTEE_DISPOSITION.DECLINED]: ['red', colorRed],
  [INV_GUARANTEE_DISPOSITION.PENDING]: ['slate', colorSlate],
  [INV_GUARANTEE_DISPOSITION.IN_REVIEW]: ['slate', colorSlate],
  [INV_GUARANTEE_DISPOSITION.ESCALATED]: ['slate', colorSlate],
  [INV_GUARANTEE_DISPOSITION.CANCELED]: ['red', colorRed],
  [INV_GUARANTEE_DISPOSITION.CANCELLED]: ['red', colorRed],
  [INV_GUARANTEE_DISPOSITION.PROCESSING_REGUARANTEE]: ['slate', colorSlate],
  [INV_GUARANTEE_DISPOSITION.UNDEFINED_DISPOSITION]: ['slate', colorSlate],
}

const DEFAULT_BG_COLOR = ['glitter', colorGlitter]

interface Props {
  disposition: InvestigationInfo['guaranteeDisposition']
  showText?: boolean
}

const GuaranteeTag: FC<Props> = ({ disposition, showText = true }) => {
  const { t } = useTranslation()

  if (disposition === null || disposition === '') {
    return null
  }

  const color = colors[disposition as keyof typeof colors] || DEFAULT_BG_COLOR

  const isLoading = [
    INV_GUARANTEE_DISPOSITION.PROCESSING_REGUARANTEE,
    INV_GUARANTEE_DISPOSITION.UNDEFINED_DISPOSITION,
  ].includes(disposition)

  return (
    <SigTag
      type="secondary"
      color={color[0]}
      className={isLoading ? styles.loading : ''}
    >
      <i className="anticon">
        <GuaranteeIcon transparent fill="white" />
      </i>
      {showText && <span>{t(`guaranteeTag.${disposition}`)}</span>}
    </SigTag>
  )
}

export default GuaranteeTag
