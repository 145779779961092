import { SigTag } from '@signifyd/components'
import { FC } from 'react'
import { SEARCH_FIELD } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import {
  colorCerulean,
  colorLicorice,
  colorWhite,
  colorYonderLight80,
} from '@signifyd/colors'
import { useNavigate } from 'react-router'
import DateRangeFilter from 'core/components/SearchAndFilter/FiltersContainer/DateRangeFilter'
import { useStoreActions } from 'store'
import { FilterState } from 'store/search'
import { getEndOfDay, getPastStartDate } from 'core/utils/date.utils'
import { getSelectedQuickDateFilter } from 'core/utils/quickDateFilter/quickDateFilter'
import styles from './HeadlineDatePicker.less'

interface Props {
  filters: FilterState
  updateFilters: (filters: FilterState) => void
}

export const dateOptions: Array<number> = [30, 14, 7, 1]

const HeadlineDatePicker: FC<Props> = ({ filters, updateFilters }) => {
  const { t } = useTranslation()
  const { normalizedPurchaseCreatedAt: dates } = filters

  const { searchCases, setCurrentPage } = useStoreActions(
    (actions) => actions.search
  )
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      {dateOptions.map((dayCount) => {
        const isSelected = getSelectedQuickDateFilter(dates, dayCount)

        return (
          <SigTag
            key={dayCount}
            type="primary"
            color={isSelected ? colorCerulean : colorYonderLight80}
            style={{ color: isSelected ? colorWhite : colorLicorice }}
            className={styles.date}
            data-analytics-id={`headline-date-picker-${dayCount}`}
            data-test-id={`headlineDatePicker${dayCount}${
              isSelected ? 'Active' : ''
            }`}
            onClick={() => {
              updateFilters({
                ...filters,
                ...{
                  [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
                    min: getPastStartDate({
                      subtractInterval: 'd',
                      subtractValue: dayCount,
                    }),
                    max: getEndOfDay(),
                  },
                },
              })
              setCurrentPage(1)
              searchCases({
                quickSearchKey: undefined,
                saveAs: '',
                savedFilterKey: undefined,
                navigate,
              })
            }}
          >
            {t('aggregates.lastDays', { count: dayCount })}
          </SigTag>
        )
      })}

      <DateRangeFilter
        value={dates}
        updateFilterFn={(newFilters) => {
          updateFilters({
            ...filters,
            ...{
              [SEARCH_FIELD.normalizedPurchaseCreatedAt]:
                newFilters.normalizedPurchaseCreatedAt,
            },
          } as FilterState)
          searchCases({
            quickSearchKey: undefined,
            saveAs: '',
            savedFilterKey: undefined,
            navigate,
          })
        }}
      />
    </div>
  )
}

export default HeadlineDatePicker
