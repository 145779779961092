/* eslint-disable react/jsx-indent */
import { FC } from 'react'
import { Card, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo, INV_STATUS } from '@signifyd/http'
import { ErrorBoundary, Text, T4 } from '@signifyd/components'
import { useStoreState } from 'store'
import { ANALYSIS_STATE } from 'store/currentCase/types/case.analysis.types'
import WarningPanel from './WarningPanel'
import CaseIntelligencePanel from './CaseIntelligencePanel'

interface Props {
  isLarge: boolean
  caseDetails: InvestigationInfo
}

export const CaseIntelligence: FC<Props> = ({ isLarge, caseDetails }) => {
  const { t } = useTranslation()

  const { investigationStatus, signifydScore } = caseDetails

  const analysisState = useStoreState(
    (state) => state.currentCase.analysisState
  )

  const showData = analysisState === ANALYSIS_STATE.PROCESSED
  const showScore =
    showData && investigationStatus !== INV_STATUS.HELD && signifydScore >= 0

  const title = (
    <Row justify="space-between">
      <T4>{t('caseIntelligence.title')}</T4>
      {showScore && (
        <Text size="lg" data-test-id="score" weight="semibold">
          {t('caseIntelligence.score', { score: Math.trunc(signifydScore) })}
        </Text>
      )}
    </Row>
  )

  return (
    <Card
      title={title}
      data-test-id="caseIntelligence"
      data-analytics-id="case-intelligence"
      size="small"
      style={{ height: !showData ? '172px' : 'inherit' }}
    >
      <ErrorBoundary message={t('errorBoundaries.intelligence')}>
        <>
          {showData && (
            <CaseIntelligencePanel
              isLarge={isLarge}
              caseDetails={caseDetails}
            />
          )}

          {!showData && <WarningPanel analysisState={analysisState} />}
        </>
      </ErrorBoundary>
    </Card>
  )
}

export default CaseIntelligence
