import { FC } from 'react'
import {
  T2,
  SigTag,
  Space,
  ExternalLink,
  CheckoutTag,
} from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import {
  InvestigationInfo,
  INV_CASE_TYPE,
  DECISION_MECHANISM,
  USER_ROLE,
} from '@signifyd/http'
import { InfoCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { PLATFORM_URL_PREFIXES_BY_TEAM_ID } from 'core/constants'
import GuaranteeTag from 'core/components/GuaranteeTag'
import FormattedAmount from 'core/components/FormattedAmount'
import RecommendationTag from 'core/components/RecommendationTag'
import ReturnTag from 'core/components/ReturnTag'
import { showReturnsTag } from 'core/utils/filterReturns'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { useStoreState } from 'store'
import CaseHeaderDetails from './CaseHeaderDetails'
import styles from './CaseHeader.less'
import AirlineNameRecord from './AirlineNameRecord'

interface Props {
  caseDetails: InvestigationInfo | null
}

export const CaseHeader: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const currentUser = useStoreState((state) => state.user.currentUser)

  if (!caseDetails) {
    return null
  }

  const displayReturnsTag = showReturnsTag(caseDetails.returns)
  const isAirlineOrder = isTransportAirline(caseDetails.products)

  const isPolicyOnlyDecisionMechanism =
    caseDetails.decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT

  return (
    <>
      <div className={styles.orderTitle}>
        <T2
          className={styles.orderTitleText}
          data-test-id="orderTitleText"
          data-analytics-id="order-title-text"
        >
          {isAirlineOrder && (
            <AirlineNameRecord products={caseDetails.products} />
          )}
          <span className={styles.orderItem}>
            {t('pages.caseReview.header.order')}{' '}
            {PLATFORM_URL_PREFIXES_BY_TEAM_ID[caseDetails.teamId] ? (
              <ExternalLink
                target="_blank"
                url={`${PLATFORM_URL_PREFIXES_BY_TEAM_ID[caseDetails.teamId]}/${
                  caseDetails.platformOrderId || caseDetails.orderExternalId
                }`}
              >
                {caseDetails.platformOrderId || caseDetails.orderExternalId}
              </ExternalLink>
            ) : (
              caseDetails.orderExternalId
            )}
          </span>
          <span className={styles.orderItem}>{caseDetails.headline}</span>
          <span className={styles.orderText}>
            <FormattedAmount caseDetails={caseDetails} />
          </span>
        </T2>

        <div className={styles.orderTags}>
          {!isPolicyOnlyDecisionMechanism &&
            caseDetails.recommendedDecisionDisposition && (
              <div data-test-id="recommendationTag">
                <RecommendationTag
                  tooltipPlacement="bottom"
                  recommendedDecisionDisposition={
                    caseDetails.recommendedDecisionDisposition
                  }
                />
              </div>
            )}
          {caseDetails.guaranteeDisposition && (
            <div data-test-id="guaranteeTag">
              <GuaranteeTag disposition={caseDetails.guaranteeDisposition} />
            </div>
          )}
          <CheckoutTag
            isRulesBuilderUser={currentUser?.roles.includes(
              USER_ROLE.RULES_BUILDER
            )}
            investigationInfoPolicyDetails={caseDetails}
            showNoMatch={false}
          />
          {displayReturnsTag && (
            <div data-test-id="returnTag">
              <ReturnTag returns={caseDetails.returns} />
            </div>
          )}
          {caseDetails.caseType === INV_CASE_TYPE.TRIAL && (
            <div data-test-id="trialOrder">
              <Tooltip
                placement="bottom"
                title={t('pages.caseReview.header.trialOrderTooltip')}
              >
                <SigTag type="primary" color="gold">
                  <InfoCircleFilled />
                  <span>{t('pages.caseReview.header.trialOrder')}</span>
                </SigTag>
              </Tooltip>
            </div>
          )}
          {caseDetails.isTestInvestigation && (
            <div data-test-id="testOrder">
              <Tooltip
                placement="bottom"
                title={t('pages.caseReview.header.testOrderTip')}
              >
                <SigTag type="primary" color="gold">
                  <InfoCircleFilled />
                  <span>{t('pages.caseReview.header.testOrder')}</span>
                </SigTag>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <Space size={12} />
      <CaseHeaderDetails caseDetails={caseDetails} />
    </>
  )
}

export default CaseHeader
