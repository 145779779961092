import {
  memo,
  FC,
  useMemo,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react'
import { Table } from 'antd'
import { InvSummaryViewInfo } from '@signifyd/http'
import { Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { SorterResult, SortOrder } from 'antd/lib/table/interface'
import { useNavigate } from 'react-router'
import PageSizeContext, { PAGE_SIZE } from 'core/components/PageSizeContext'
import { FilterState } from 'store/search'
import { useStoreActions, useStoreState } from 'store'
import { getColumnConfig } from './SearchResultsTable.config'
import TableTitle from './TableTitle'
import styles from './SearchResultsTable.less'

interface Props {
  loading?: boolean
  searchResults: Array<InvSummaryViewInfo>
  hasAirlineOrders: boolean
  autodetectTimezone?: boolean
  currentTimezone?: string
}

const DEFAULT_PAGE = 1
const sortOrderMapping = {
  ascend: 'descend',
  descend: 'ascend',
}

const SearchResultsTable: FC<Props> = ({
  loading,
  searchResults,
  hasAirlineOrders,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // Store State/Actions
  const { setPageSize, setCurrentPage, setSortOrder, getSearchResults } =
    useStoreActions((actions) => actions.search)
  const resultsTotal = useStoreState((state) => state.search.totalResults)
  const currentPage = useStoreState((state) => state.search.currentPage)
  const tablePageSize = useStoreState((state) => state.search.pageSize)

  const { sort } = useStoreState((state) => state.search)
  const { hiddenColumns } = useStoreState((state) => state.user)

  // Component State
  const pageSize = useContext(PageSizeContext)

  const columnConfig = useMemo(() => {
    return getColumnConfig({
      loading,
      smallSize: pageSize === PAGE_SIZE.MD_AND_SMALLER,
      sort,
      hiddenCols: hiddenColumns,
      hasAirlineOrders,
    })
  }, [loading, pageSize, sort, hiddenColumns, hasAirlineOrders])

  useEffect(() => {
    setCurrentPage(DEFAULT_PAGE)
  }, [sort, setCurrentPage])

  // Handlers
  const onSortChange = (newSort: SorterResult<InvSummaryViewInfo>): void => {
    setSortOrder({
      by: newSort.columnKey as keyof FilterState,
      order: newSort.order ?? (sortOrderMapping[sort.order!] as SortOrder),
    })
  }

  const handlePaginationChange = (
    pageNumber: number,
    pageSize?: number
  ): void => {
    if (pageSize) {
      setPageSize(pageSize)
    }

    setCurrentPage(pageNumber)
    getSearchResults()
  }

  return (
    <>
      {!loading && (
        <TableTitle
          hasAirlineOrders={hasAirlineOrders}
          resultsTotal={resultsTotal || 0}
        />
      )}
      <Space size="lg" />

      <Table
        rowKey="investigationId"
        loading={loading}
        className={styles.table}
        rowClassName={styles.tableRow}
        dataSource={searchResults}
        components={{
          header: {
            row: ({
              children,
              ...props
            }: PropsWithChildren<Record<string, unknown>>) => (
              <tr data-test-id="header" {...props}>
                {children}
              </tr>
            ),
          },
          body: {
            row: ({
              children,
              ...props
            }: PropsWithChildren<Record<string, unknown>>) => (
              <tr data-test-id={props['data-row-key']} {...props}>
                {children}
              </tr>
            ),
          },
        }}
        columns={columnConfig}
        scroll={{ x: true }}
        pagination={{
          total: resultsTotal || 0,
          current: currentPage,
          pageSize: tablePageSize,
          showTotal: (total, range) =>
            t('pages.results.table.currentRange', {
              total,
              start: range[0],
              end: range[1],
            }),
          onShowSizeChange: (current, size) =>
            handlePaginationChange(current, size),
          onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
          size: 'small',
          defaultPageSize: 25,
          pageSizeOptions: ['25', '50', '75'],
          showSizeChanger: true,
          className: styles.pagination,
          position: ['bottomCenter'],
        }}
        onRow={({ investigationId }: InvSummaryViewInfo) => ({
          onClick: () => navigate(`/orders/${investigationId}`),
        })}
        onChange={({ current }, _filter, sorter) => {
          if (current !== currentPage) {
            return
          }

          // We don't currently support multi-sort
          onSortChange(Array.isArray(sorter) ? sorter[0] : sorter)
        }}
      />
    </>
  )
}

export default memo(SearchResultsTable)
