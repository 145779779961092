import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LockFilled } from '@ant-design/icons'
import { Button, Menu, Tooltip } from 'antd'
import { SEARCH_FIELD } from '@signifyd/http'
import styles from './TableColumnListItem.less'

interface Props {
  isLocked: boolean
  isHidden: boolean
  title: SEARCH_FIELD
  onToggleHidden: () => void
}

const TableColumnListItem: FC<Props> = ({
  isLocked,
  title,
  isHidden,
  onToggleHidden,
}) => {
  const { t } = useTranslation()
  const columnTitle = t(`pages.results.columnSelect.keys.${title}`)

  return isLocked ? (
    <Tooltip
      placement="top"
      arrow={{ pointAtCenter: true }}
      title={<div>{t('pages.results.columnSelect.tooltip')}</div>}
    >
      <Menu theme="light" className={styles.menu} selectable={false}>
        <Menu.Item disabled className={styles.menuItem}>
          <span>
            <LockFilled
              data-test-id="column-locked"
              className={styles.lockIcon}
            />
            {columnTitle}
          </span>
        </Menu.Item>
      </Menu>
    </Tooltip>
  ) : (
    <Menu theme="light" className={styles.menu} selectable={false}>
      <Menu.Item>
        <div className={styles.menuItem}>
          <span>{columnTitle}</span>
          <Button
            type="link"
            data-test-id={`${title}-drawer-button-${
              isHidden ? 'show' : 'hide'
            }`}
            onClick={onToggleHidden}
          >
            {t(
              `pages.results.columnSelect.${isHidden ? 'show' : 'hide'}Column`
            )}
          </Button>
        </div>
      </Menu.Item>
    </Menu>
  )
}

export default TableColumnListItem
