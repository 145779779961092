import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary, P, Space, T2 } from '@signifyd/components'
import { Row } from 'antd'
import { StringParam, useQueryParams } from 'use-query-params'
import { useStoreActions, useStoreState } from 'store'
import {
  defaultSearchFilters,
  defaultSortState,
} from 'store/search/search.store'
import PageWrapper from 'core/components/PageWrapper'
import SearchAndFilter from 'core/components/SearchAndFilter'
import NotFound from 'core/components/NotFound'
import TeamSelectModal from 'core/components/TeamSelectModal'
import { useSearchFilters } from 'core/hooks'
import { encodeUrlHash } from 'core/utils/urlEncoder'
import { RangeFilter, SearchFilterState } from 'store/search'
import SearchResultTable from './components/SearchResultsTable'
import styles from './SearchResultsPage.less'
import useUserHasAirlineOrders from './queries/useUserHasAirlineOrders'
import HeadlineDatePicker from './components/HeadlineDatePicker/HeadlineDatePicker'
import AggregatePanel from './components/Aggregates'
import { aggregatesPanelKey } from './components/Aggregates/AggregatePanel'

export const externalParams = {
  normalizedPurchaseCreatedAt: StringParam,
  returnRecommendedActionRuleId: StringParam,
  recommendedActionRuleId: StringParam,
  guaranteeRecommendedActionRuleId: StringParam,
}

const getNormalisedPurchaseCreatedDate = (
  queryDate?: string | null
): RangeFilter<string> => {
  if (queryDate) {
    const [min, max] = queryDate.split(',')

    return { min, max }
  }

  return defaultSearchFilters.normalizedPurchaseCreatedAt
}

export const SearchResultsPage: FC = () => {
  const { t } = useTranslation()
  const { searchId = '' } = useParams()
  const [query] = useQueryParams(externalParams)
  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState<string | Array<string>>([
    aggregatesPanelKey,
  ])

  const { data: hasAirlineOrders, isLoading: isOrderCheckLoading } =
    useUserHasAirlineOrders()

  const { filters, updateFilters, searchTerm, updateSearchTerm } =
    useSearchFilters({ searchId })

  // Store State
  const { populateAndSearch } = useStoreActions((actions) => actions.search)
  const searchResults = useStoreState((state) => state.search.results)
  const searchResultsLoading = useStoreState((state) => state.search.searching)
  const selectedTeams = useStoreState((action) => action.user.currentTeams)

  const loading = searchResultsLoading || isOrderCheckLoading

  // Component State
  const searchRef = useRef<HTMLDivElement>(null)

  // If we have external params, combine and encode the url then refresh
  useEffect(() => {
    if (query && !searchId) {
      const encodedHash = encodeUrlHash<SearchFilterState>({
        searchTerm,
        filters: {
          ...defaultSearchFilters,
          ...query,
          normalizedPurchaseCreatedAt: getNormalisedPurchaseCreatedDate(
            query.normalizedPurchaseCreatedAt
          ),
        },
        sort: defaultSortState,
      })

      const newSearchPath = `/orders/search/${encodedHash}`
      navigate(newSearchPath, { replace: true })
    }
  }, [searchId, query, navigate, searchTerm])

  useEffect(() => {
    if (!isOrderCheckLoading && searchId) {
      populateAndSearch(decodeURI(searchId))
    }
  }, [searchId, populateAndSearch, query, isOrderCheckLoading])

  return (
    <PageWrapper>
      <Row justify="space-between">
        <T2>{t('pages.results.title')}</T2>

        <ErrorBoundary message={t('errorBoundaries.teamSelect')}>
          <div className={styles.dateTeamContainer}>
            <HeadlineDatePicker
              filters={filters}
              updateFilters={updateFilters}
            />
            <TeamSelectModal />
          </div>
        </ErrorBoundary>
      </Row>

      <ErrorBoundary message={t('errorBoundaries.aggregatePanel')}>
        <AggregatePanel
          filters={filters}
          updateFilters={updateFilters}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          teamId={selectedTeams}
        />
      </ErrorBoundary>

      <Space size="lg" />

      <div ref={searchRef}>
        <SearchAndFilter
          defaultOpen
          filters={filters}
          updateFilters={updateFilters}
          searchTerm={searchTerm}
          updateSearchTerm={updateSearchTerm}
          hasAirlineOrders={!!hasAirlineOrders}
          setActiveKey={setActiveKey}
        />
      </div>

      <div className={styles.content}>
        {(loading || !!searchResults?.length) && (
          <ErrorBoundary message={t('errorBoundaries.searchResults')}>
            <SearchResultTable
              loading={loading}
              searchResults={searchResults}
              hasAirlineOrders={!!hasAirlineOrders}
            />
          </ErrorBoundary>
        )}
        {!loading && !searchResults.length && (
          <NotFound title={t('pages.results.noOrders.title')}>
            <P>
              {t('pages.results.noOrders.subTitle1')}
              <br />
              {t('pages.results.noOrders.subTitle2')}
            </P>
            <P>{t('pages.results.noOrders.subTitle3')}</P>
          </NotFound>
        )}
      </div>
    </PageWrapper>
  )
}

export default SearchResultsPage
